module.exports = {
  dropdownMenu() {
    function toggleMenu() {
      const dropdownBtn = document.querySelector('.js-dropdown-btn');
      const dropdownOpenClass = 'dropdown-open';
      if (dropdownBtn.parentElement.classList.contains(dropdownOpenClass)) {
        dropdownBtn.parentElement.classList.remove(dropdownOpenClass);
      } else {
        dropdownBtn.parentElement.classList.add(dropdownOpenClass);
      }
    }

    function toggleCareerDropdown() {
      const dropdownMenu = document.getElementById('careerMenu');
      const dropdownOpenClass = 'dropdown-open';
      if (dropdownMenu.classList.contains(dropdownOpenClass)) {
        dropdownMenu.classList.remove(dropdownOpenClass);
      } else {
        dropdownMenu.classList.add(dropdownOpenClass);
      }
    }

    function selectStore(e) {
      const label = document.getElementById('c-careers-popup__dropdown-label');
      const button = document.getElementById('c-careers-popup__button--apply');
      label.textContent = e.innerText;
      button.href = e.href;
      button.classList.remove('disabled');
      toggleCareerDropdown();
      toggleMenu();
    }

    function toggleOverlay() {
      const overlay = document.getElementById('careerOverlay');
      const popup = document.getElementById('careerPopup');

      const openClass = 'is-visible';
      if (overlay.classList.contains(openClass) || popup.classList.contains(openClass)) {
        overlay.classList.remove(openClass);
        popup.classList.remove(openClass);
      } else {
        overlay.classList.add(openClass);
        popup.classList.add(openClass);
      }
    }

    function toggleMobileFood() {
      const dropdownList = document.querySelector('.c-food-feature__nav');
      const dropdownOpenClass = 'dropdown-open';
      if (dropdownList.parentElement.classList.contains(dropdownOpenClass)) {
        dropdownList.parentElement.classList.remove(dropdownOpenClass);
      } else {
        dropdownList.parentElement.classList.add(dropdownOpenClass);
      }
    }

    const dropdownBtn = document.querySelector('.js-dropdown-btn');
    const careerDropdown = document.getElementById('careerDropdown');
    const careerButton = document.getElementById('c-careers-popup__button--apply');
    const closeButton = document.getElementById('closeCareerModal');
    const mobileFoodButton = document.querySelector('.food-nav');


    if (mobileFoodButton) {
      mobileFoodButton.addEventListener('click', (e) => {
        e.preventDefault();
        toggleMobileFood();
      });
    }

    dropdownBtn.addEventListener('click', (e) => {
      e.preventDefault();
      toggleMenu();
    });

    careerDropdown.addEventListener('click', (e) => {
      e.preventDefault();
      toggleCareerDropdown();
    });

    document.addEventListener('click', (e) => {
      if (!e.target.matches('.c-careers-popup__dropdown-nav-item')) return;
      e.preventDefault();
      console.log(e.target);
      selectStore(e.target);
    }, false);

    careerButton.addEventListener('click', (e) => {
      e.preventDefault();
      toggleOverlay();
    });

    closeButton.addEventListener('click', (e) => {
      e.preventDefault();
      toggleOverlay();
    });

    document.addEventListener('click', (e) => {
      if (!e.target.matches('.c-cta--careers')) return;
      e.preventDefault();
      toggleOverlay();
    }, false);

    document.addEventListener('click', (e) => {
      if (e.target.matches('.l-footer__link') && e.target.href.indexOf('careers') > -1) {
        e.preventDefault();
        toggleOverlay();
      }
    }, false);

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
        if (isNotCombinedKey) {
          toggleOverlay();
        }
      }
    });
  },
};
