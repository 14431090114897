function toggleLocationPanel(location) {
  /* eslint-disable  */
  const locationPanel = document.querySelector('.c-location-banner');
  if(location && location.locationDynamicFields){
    const storeHours = location.locationDynamicFields.storeHours;
  }else{
    const storeHours = {};
  }


  try {
    const {
      c_locationShortName: shortname,
      name,
      mainPhone,
      c_locationSlug: slug,
      orderUrl,
      storeHours: hours,
      c_mapUrl: mapUrl,
      address,
      city,
      region,
      postalCode,
    } = location;

    /* ignore-comma-dangle */

    const openHours = function () {
      const weekday = ["sun", "mon", "tues", "wed", "thu", "fri", "sat"];
      const d = new Date();
      let day = weekday[d.getDay()];
      let hour = d.getHours();
      let minutes = d.getMinutes();
      let currentTime = parseInt("" + hour + minutes);
      let openTime;
      let closedTime = parseInt(hours[day].close.replace(/\D/g, ""));

      if (hours[day].isClosed === true || (hours[day].start === "00:00:00" && hours[day].close === "00:00:00")) {
        return "Closed Today"
      } else {
        return "Open today from " + hours[day].start + " to " + hours[day].close;
      }
      return '';
    }

    function returnAddress(){
      if ( typeof address === 'object' && !Array.isArray(address) && address !== null) {
       return address.line1;
      }else{
        return address;
      }
    }

    locationPanel.classList.remove('hide-initial');

    const locTemplate = `<div class="c-location-banner--panel banner-top">
      <img src="/_assets/images/icons/favorite-icon.svg" alt="Star icon"/>
      <a href="/locations/details/${slug}">${shortname ? shortname.toLowerCase() : name.toLowerCase()}</a>
      <span class="float-right expand-icon" id="locationPanelToggle"></span>
    </div>
    <div class="c-location-banner--panel banner-details">
      <div class="banner-details--top">
        <p>${returnAddress()}<br>${city}, ${region} ${postalCode}</p>
      </div>
      <div class="banner-details--bottom">
        <ul>
          <li><img src="/_assets/images/icons/map-icon.svg?a=1" alt="Get directions"/> <a href="${mapUrl}">get directions</a></li>
          <li><a href="/locations/details/${slug}"><img src="/_assets/images/icons/info-icon.svg" alt="Store details"/> store details</a></li>
          <li><img src="/_assets/images/icons/phone-icon.svg" alt="Phone icon" /> <b><a href="tel:${mainPhone}">${mainPhone}</a></b></li>
          <li><img src="/_assets/images/icons/wing-icon.svg" alt="order online icon" /> <a href="${orderUrl}">order online</a></li>
        </ul>
      </div>
    </div>
    <div class="c-location-banner--panel banner-bottom">
      <a href="/locations"><img src="/_assets/images/icons/map-icon.svg?a=1" alt="Choose different location icon" id="chooseDifferentLocation"/> choose different location</a>
    </div>`;
    locationPanel.innerHTML = locTemplate;
    document
      .getElementById('locationPanelToggle')
      .addEventListener('click', () => {
        const banner = document.querySelector('.c-location-banner');
        if (banner.classList.contains('collapsed')) {
          banner.classList.remove('collapsed');
          localStorage.bannerCollapsed = 0;
        } else {
          banner.classList.add('collapsed');
          localStorage.bannerCollapsed = 1;
        }
      });
  } catch (e) {
    locationPanel.innerHTML = `<div class="c-location-banner--panel banner-top">
        <img src="/_assets/images/icons/favorite-icon.svg" alt="Favorite icon" />
        <a href="/locations" style="text-decoration:none;" id="chooseFavoriteLocation">choose favorite</a>
        <span class="float-right expand-icon" id="locationPanelToggle"></span>
      </div>
      <div class="c-location-banner--panel banner-details">
        <div class="banner-details--top">
        </div>
        <div class="banner-details--bottom">
        </div>
      </div>
      <div class="c-location-banner--panel banner-bottom">
        <a href="/locations" id="chooseDifferentLocation"><img src="/_assets/images/icons/map-icon.svg?a=1" alt="Choose different location"/> choose different location</a>
      </div>`;
  }
}
function retrieveFavoriteLocation(locationId) {
  fetch('/api/index.php?location-id=' + slug, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json());
}

module.exports = {
  toggleLocationPanel,
  retrieveFavoriteLocation,
};
