import gtmClassClick from './utils/gtm';
import navigation from './components/navigation';
import pageScroll from './components/pageScroll';
import { dropdownMenu } from './components/dropdownMenu';
import externalPopup from './components/externalPopup';
import { toggleLocationPanel } from './components/locationPanel';
// import forms from './components/forms';
/* eslint-disable */
/* eslint-disable indent */

var acc = document.getElementsByClassName('accordion');
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener('click', function () {
    this.classList.toggle('active');
    var panel = this.nextElementSibling;
    if (panel.style.display === 'block') {
      panel.style.display = 'none';
    } else {
      panel.style.display = 'block';
    }
  });
}
function setDivHeight(selector) {
  let divHeight = [];
  let divs = document.querySelectorAll(selector);
  divs.forEach(div => divHeight.push(div.clientHeight));
  divs.forEach(
    div => (div.style.height = divHeight.sort()[divHeight.length - 1] + 'px')
  );
}
document.addEventListener('DOMContentLoaded', function () {
  if (window.location.pathname.includes('/food')) {
    dropdownMenu();
    let tileTitles;

    if (window.location.pathname.includes('sauces-and-rubs')) {
      tileTitles = document.querySelectorAll('.c-food-sauce');
      tileTitles.forEach(tile => {
        if (tile.innerText.includes('*')) {
          let title = tile.innerText.split('*');
          tile.children[0].innerHTML =
            title[0] + '<span style="color:#ff4000">*</span>';
        }
      });
    } else {
      tileTitles = document.querySelectorAll('.c-food-tile__title');
      tileTitles.forEach(tile => {
        if (tile.innerText.includes('*')) {
          let title = tile.innerText.split('*');
          tile.innerHTML = title[0] + '<span style="color:#ff4000">*</span>';
        }
      });
    }
    //Find menu item titles and change the asterisk to orange

    document
      .querySelector("[href='" + window.location.pathname + "']")
      .parentNode.classList.add('active');
  }

  setDivHeight('.c-food-tile__content.c-food-tile__content--info');
  const { page } = document.body.dataset;
  console.info(`Current Page: ${page}`); // eslint-disable-line no-console
  document.querySelector('[href="/hoot-scoop"]').classList.add('hootscoop');

  // Activate Custom Click Events for GTM
  if (localStorage.bannerCollapsed === '1') {
    document.querySelector('.c-location-banner').classList.add('collapsed');
  } else {
    document.querySelector('.c-location-banner').classList.remove('collapsed');
  }
  if (localStorage.getItem('favoriteLocation')) {
    const slug = JSON.parse(localStorage.favoriteLocation).c_locationSlug;

    fetch('/api/index.php?location-slug=' + slug, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response =>
      response.json().then(data => {
        const favoriteStore = data.location;
        toggleLocationPanel(data.location);
      })
    );
  } else {
    toggleLocationPanel();
  }

  gtmClassClick();
  pageScroll();
  navigation();
  // forms();
  externalPopup();

  try {
    switch (page) {
      // Home Page
      case 'home':
        // home specific js
        break;
      default:
        // handle non-page specific elsewhere
        break;
    }
  } catch (e) {
    console.error('Failed to run page-specific JS.', e); // eslint-disable-line no-console
  }
});
