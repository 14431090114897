export default function navigation() {
  const body = document.querySelector('body');
  const mobileNavBtn = document.querySelector('.js-menu-button');
  const menuOpenClass = 'menu-open';

  mobileNavBtn.addEventListener('click', () => {
    if (body.classList.contains(menuOpenClass)) {
      body.classList.remove(menuOpenClass);
    } else {
      body.classList.add(menuOpenClass);
    }
  });
}
