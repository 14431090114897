export default function pageScroll() {
  window.addEventListener('scroll', () => {
    const body = document.querySelector('body');
    if (window.scrollY > 10) {
      body.classList.add('page-scrolling');
    } else {
      body.classList.remove('page-scrolling');
    }
  });
}
