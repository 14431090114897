export default function externalPopup() {
  // const $document = $(document);
  const $element = document.querySelector('.js-extpop-element');
  const $overlay = document.querySelector('.js-extpop-overlay');
  const $link = document.querySelector('.js-extpop-link');
  const $number = document.querySelector('.js-extpop-number');
  const count = 5;
  let timer = {};

  /**
   * Show the popup modal
   */
  function showPopup() {
    $overlay.classList.add('is-visible');
    $element.classList.add('is-visible');
    $element.attributes['aria-hidden'] = false;
    // $link.focus();
    window.dataLayer.push({
      event: 'external_link',
      link: $link.href,
      target: $link.target,
    });
  }

  /**
   * Hide the popup modal
   */
  function hidePopup() {
    $number.innerHTML = count;
    $element.classList.remove('is-visible');
    $element.attributes['aria-hidden'] = false;
    $overlay.classList.remove('is-visible');
  }

  /**
   * Stop the countdown
   */
  function stopCountdown() {
    clearInterval(timer);
  }

  /**
   * Redirect to the URL of the modal link, stop the countdown and hide the modal
   */
  function proceed() {
    stopCountdown();
    hidePopup();
    if ($link.target === '_blank') {
      window.open($link.href, '_blank');
    } else {
      window.location = $link.href;
      $link.href = '#';
    }
  }

  /**
   * Start the countdown and then proceed on completion
   */
  function startCountdown() {
    let number = count - 1;
    timer = setInterval(() => {
      $number.innerHTML = number;
      if (number <= 0) {
        proceed();
      }
      number -= 1;
    }, 1000);
  }

  /**
   * Attach click event to all links
   */
  function attachLinkEvents() {
    [].forEach.call(document.querySelectorAll('a:not(.js-extpop-link):not(.js-dropdown-btn)'), (el) => {
      el.addEventListener('click', (e) => {
        if (e.target.target === '_blank') {
          console.log(e);
          e.preventDefault();
          $link.href = e.target.href;
          showPopup();
          startCountdown();
        }
      });
    });

    $link.addEventListener('click', (e) => {
      e.preventDefault();
      window.dataLayer.push({
        event: 'external_link_impatient',
        link: e.target.href,
        target: e.target.target,
      });
      proceed();
    });
    // $link.on('click.external', (e) => {
    //   e.preventDefault();
    //   window.dataLayer.push({
    //     event: 'external_link_impatient',
    //     link: $link.attr('href'),
    //     target: $link.attr('target'),
    //   });
    //   proceed();
    // });
  }

  function attachDocumentEvents() {
    // Keep Focus in Modal
    document.addEventListener('keydown', (e) => {
      if (e.code.toLowerCase() === 'tab' && $overlay.attributes['aria-hidden'] === false) {
        e.preventDefault();
      }
      if (e.code.toLowerCase() === 'escape' || e.code.toLowerCase() === 'backspace') {
        stopCountdown();
        hidePopup();
      }
    });
  }

  // function removeEvents() {
  //   $('a')
  //     .not($link)
  //     .off('click.external');
  //   $link.off('click.external');
  //   $document.off('keydown.external');
  // }

  function init() {
    // removeEvents();
    attachLinkEvents();
    attachDocumentEvents();
  }

  init();
}
