/**
 * send a custom click event to GTM on every click
 */
export default function gtmClassClick() {
  document.body.addEventListener('click', (event) => {
    let path = '';
    let node = event.target;

    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [];
    }

    while (node) {
      let name = node.localName;
      if (!name) break;
      name = name.toLowerCase();

      const parent = node.parentElement;
      const classNames = node.classList
        .toString()
        .trim()
        .replace(/\s+/g, '.')
        .replace(/.*\.+$/, '');
      const id = node.id || '';

      name += `${id ? `#${id}` : ''}`;
      name += `${classNames ? `.${classNames}` : ''}`;

      if (parent) {
        const sameTagSiblings = parent.querySelectorAll(name);
        if (sameTagSiblings.length > 1) {
          const allSiblings = Array.prototype.slice.call(parent.children);
          const index = allSiblings.indexOf(node);
          if (index > 0) {
            name += `:nth-child(${index})`;
          }
        }
      }

      path = `${path ? `${name}>${path}` : name}`;

      node = parent;
    }

    window.dataLayer.push({
      event: 'customClick',
      cssSelector: path,
    });
  });
}
